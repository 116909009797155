@import url('https://fonts.googleapis.com/css2?family=Gluten:wght@400;600;700&family=Grandstander:wght@400;600;700&display=swap');

.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.gluten {
  font-family: 'Gluten', cursive !important;
  font-size: 110px;
  letter-spacing: -12px;
  text-shadow: 2px 2px 0 #EAA611, 4px 4px 0 #EAA611;
}

.grandstander {
  font-family: 'Grandstander', cursive !important;
  font-weight: 600;
  font-size: 26px;
  text-align: center;
}

.grand {
  font-family: 'Grandstander', cursive !important;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-7.5x);
  }
}

@keyframes bounceIn {
  0% {
    bottom: -50px;
    opacity: 0;
  }
  50% {
    bottom: 30px;
    opacity: 1;
  }
  100% {
    bottom: 20px;
    opacity: 1;
  }
}

.bounce {
  animation: bounceIn 0.5s ease-in-out;
  bottom: 20px; /* Ensure it stays in place after animation */
  opacity: 1; /* Ensure it stays visible after animation */
}